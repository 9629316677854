import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/i18n/i18n-validators";
// Composables
import useOptions from "@/composables/useOptions";
// Constants
import inputTypes from "@/constants/inputTypes";

export default function useCompetency() {
  // Misc
  const { t } = useI18n();

  const {
    ALL_OPTIONS,
    defaultSystemOptions,
    defaultStatusOptions,
    getDepartments
  } = useOptions();

  // CONSTANTS
  const view_code = "Competencies";
  const route = "teaching-competencies";
  const endpoint = "teaching.competencies";
  const searchFields = ["name"];
  const types = {
    name: inputTypes.TEXT,
    details: inputTypes.TEXT,
    department_id: inputTypes.SELECT,
    colour: inputTypes.COLOR,
    status: inputTypes.SELECT,
    sys_admin_locked: inputTypes.SELECT
  };
  const localizedFields = ["name", "details"];
  const headers = [
    {
      value: "image",
      class: "w-10"
    },
    {
      text: t("app.name"),
      value: "name"
    },
    {
      text: t("app.departments"),
      value: "department"
    },
    {
      text: t("app.status"),
      value: "status",
      class: "w-40"
    },
    {
      text: t("app.actions"),
      value: "__ACTIONS",
      class: "w-56"
    }
  ];

  // DATA
  let value = ref({
    name: null,
    details: null,
    department_id: "",
    colour: "",
    status: ALL_OPTIONS.ACTIVE.value,
    sys_admin_locked: ALL_OPTIONS.NO.value
  });
  let rules = ref({
    name: { required },
    details: { required },
    department_id: { required },
    colour: {},
    status: { required },
    sys_admin_locked: { required }
  });

  const options = ref({
    department_id: [],
    status: defaultStatusOptions,
    sys_admin_locked: defaultSystemOptions
  });

  // METHODS
  const getOptions = async () => {
    options.value.department_id = await getDepartments();

    return options.value;
  };

  return {
    headers,
    view_code,
    types,
    route,
    endpoint,
    searchFields,
    value,
    rules,
    options,
    getOptions,
    getDepartments,
    localizedFields
  };
}
