<template>
  <div>
    <VCard>
      <template #content>
        <div class="flex justify-between items-center">
          <div class="text-gray-600">
            {{ $t("app.created_on") }}

            <span class="font-medium text-gray-600">
              {{ created }}
            </span>
          </div>

          <div class="text-gray-600">
            {{ $t("app.last_modified_on") }}

            <span class="font-medium text-gray-600">
              {{ updated }}
            </span>
          </div>

          <VButton
            v-if="!hideAction"
            class="btn-outline-secondary text-gray-600"
            :label="$t('app.change_log')"
            @click="$emit('click', $event)"
          />
        </div>
      </template>
    </VCard>
  </div>
</template>

<script>
import { computed } from "vue";
// Composables
import useDisplay from "@/composables/useDisplay";
// Components
import VCard from "@/components/VCard";
import VButton from "@/components/VButton";

export default {
  components: {
    VCard,
    VButton
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    hideAction: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click"],
  setup(props) {
    // Composables
    const { formatDate } = useDisplay();

    // Computed
    const created = computed(() => formatDate({ date: props.data.created_at }));
    const updated = computed(() => formatDate({ date: props.data.updated_at }));

    return {
      created,
      updated
    };
  }
};
</script>
