<template>
  <div class="relative flex flex-col">
    <div class="flex justify-between items-center">
      <VTitle :title="$t('app.details')" class="section-title" />

      <LangDropdown
        display-reset
        :lang="lang"
        :texts="competency.texts"
        :endpoint="`${endpoint}.texts.delete`"
        attribute="competency_id"
        @update="onUpdateLang"
        @cleared="$emit('refresh')"
      />
    </div>

    <Form
      :is-loading="isLoading"
      class="z-10"
      :data="data"
      @click:cancel="$emit('click:cancel')"
      @click:save="$emit('click:save', $event)"
    />

    <Logs :data="data" class="mt-8 z-0" hide-action />
  </div>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useLangDropdown from "@/composables/useLangDropdown";
import useTexts from "@/composables/useTexts";
import useCompetency from "@/composables/useCompetency";
// Components
import VTitle from "@/components/VTitle";
import LangDropdown from "@/components/LangDropdown";
import Logs from "@/components/Logs";
import Form from "./Form";

export default {
  components: {
    VTitle,
    Form,
    Logs,
    LangDropdown
  },
  props: {
    id: {
      type: String,
      required: true
    },
    competency: {
      type: Object,
      default: () => ({})
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click:save", "click:cancel", "refresh"],
  setup(props) {
    // MISC
    const { t } = useI18n();

    // COMPUTED
    const data = computed(() => props.competency);
    const texts = computed(() => props.competency.texts);
    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.details")} - ${t("app.competencies", 2)} - ${t(
        "app.teaching"
      )}`;

      const name = getText(texts.value, "name");
      if (name) {
        return `${name} - ${title}`;
      }

      return title;
    });

    // CUSTOM COMPOSABLES
    const { endpoint, localizedFields } = useCompetency();
    const { getText } = useTexts();
    const { lang, updateLang } = useLangDropdown({
      texts,
      localizedFields
    });

    return {
      documentTitle,
      data,
      // useCompetency
      endpoint,
      // useLangDropdown
      lang,
      onUpdateLang: updateLang
    };
  }
};
</script>
