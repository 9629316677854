<template>
  <FormCard
    :is-loading="isLoading"
    @click:cancel="onCancel"
    @click:save="onSave"
  >
    <template #content>
      <div class="grid grid-cols-2 gap-x-8 gap-y-2">
        <LocalizedInput
          component="VText"
          :model-value="value.name"
          :errors="v.name"
          :label="$t('app.name')"
          :lang="lang"
          :readonly="readonly || locked"
          field="name"
          class="col-span-2"
          @update:modelValue="onUpdate"
          @keyup.enter="onSave"
        />

        <LocalizedInput
          component="VWysiwyg"
          :model-value="value.details"
          :errors="v.details"
          :label="$t('app.details')"
          :lang="lang"
          class="col-span-2"
          field="details"
          :readonly="readonly || locked"
          @update:modelValue="onUpdate"
          @keyup.enter="onSave"
        />

        <VSelect
          v-model="v.department_id.$model"
          :label="$t('app.departments', 1)"
          :errors="v.department_id.$errors"
          :options="options.department_id"
          :readonly="readonly || locked"
        />

        <VSwitch
          v-model="v.status.$model"
          :label="$t('app.status')"
          true-value="Active"
          false-value="Inactive"
          :readonly="readonly || locked"
          :errors="v.status.$errors"
        />

        <VColor
          v-model="v.colour.$model"
          :label="$t('app.colour')"
          :errors="v.colour.$errors"
          :readonly="readonly || locked"
          @keyup.enter="onSave"
        />

        <VSwitch
          v-model="v.sys_admin_locked.$model"
          :label="$t('app.sys_admin_locked')"
          true-value="Yes"
          false-value="No"
          :readonly="readonly || locked"
          :errors="v.sys_admin_locked.$errors"
        />
      </div>
    </template>
  </FormCard>
</template>

<script>
import { onMounted, inject } from "vue";
// Composables
import useForm from "@/composables/useForm";
import useCompetency from "@/composables/useCompetency";
// Mixins
import FormMixin from "@/mixins/FormMixin.js";

export default {
  mixins: [FormMixin],
  setup(props, context) {
    // MISC
    const lang = inject("lang");

    // CUSTOM COMPOSABLES
    const {
      value,
      rules,
      options,
      localizedFields,
      getOptions
    } = useCompetency();
    const { v, onUpdate, onCancel, onSave, locked, isSysAdmin } = useForm(
      props,
      context,
      {
        value,
        rules,
        localizedFields,
        lang
      }
    );

    // LIFECYLE HOOKS
    onMounted(async () => {
      await getOptions();
    });

    return {
      lang,
      // useCompetency
      value,
      options,
      // useForm
      v,
      onUpdate,
      locked,
      isSysAdmin,
      onCancel,
      onSave
    };
  }
};
</script>
